<template>
	<div class="main">
      
    <div class="main-container">
      <div v-if="isLoading" class="loading-spinner"></div>
            <div v-if="busPlanOnly.length == 0" >
              <p>This applicant has not submitted yet</p>
             </div>
           <div class="" v-for="item in busPlanOnly" :key="item.id" :item="item">
            
               <div> 
                  <button class="btn-card-upload text-white" @click.prevent="uploadBusinessP()" v-b-tooltip.hover title="Click to upload Business Plan on behalf of the applicant"> Upload Business Plan</button>
                </div>
         
           <ul class="btn-group-card" >
             <form>
               <li >
                 
                 <button class="btn-card-upload" disabled>
                   <h5 >{{item.description}}
                     <span class="indicator" v-if="item.status == 'SUBMITTED'"  >
                     <img :src="require('../assets/circle/submitted.png')"/> 
                   </span>
                   <span class="indicator" v-if="item.status == 'REJECT'"  >
                     <img :src="require('../assets/circle/rejected.png')"/> 
                   </span>
                   <span class="indicator" v-if="item.status == 'ACCEPTED'" >
                     <img :src="require('../assets/circle/approved.png')"/> <span><p>approved</p></span>
                   </span>
                   <span class="indicator" v-if="item.status == 'REJECT'" >
                       <img :src="require('../assets/circle/rejected.png')"/> <span><p>rejected - {{formatDate(item.createDate)  }} by {{ item.processedBy }}</p></span>
                     </span>
                   <span v-if="item.status == ''">
                       <p style="color: lightgray;">No business plan Found</p>
                   </span>
                 </h5>
                 <div>
                   <button class="btn btn-view"  @click.prevent="viewUpload(item)" v-b-tooltip.hover title="Click to view and action the business plan submitted by the applicant">view</button>
                   
                 </div>
                 
                 </button>
                 <p v-if="item.status == 'ACCEPTED'">Verified by: {{ item.processedBy }} on {{ formatDate(item.updateDate) }}</p>
               </li>
             </form>
             </ul>

             <hr>
            
             <hr>


             <div v-if="item.status == 'ACCEPTED'">
                <div v-if="init.numberOfTokens == 1">
                    <p >To be confirmed by the Final Approver</p>
                    <div >
                      <button class="btn btn-approve" @click.prevent="openUploadApprover('2')" :disabled="!init.FXSIGN || !init.FXAPPROVERTWO" v-b-tooltip.hover title="To be confirmed by the final approver">
                        Confirm 
                      </button>
                    </div>
                </div>

              </div>
            
           </div>

           <div v-if="init.numberOfTokens >= 2">
                  <ul v-for="data in init.tokens" :key="data.id">
                    <li v-if="data.step == '2'">
                     <h5>Confirmed on <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank" class="link" v-b-tooltip.hover title="Click to view and verify on the blockchain">blockchain</a> by: {{ data.issuedBy }} on {{ formatDate(data.updateDate) }}</h5> 
                     <!-- <button class="btn btn-secondary"> <a :href="'https://explorer.blxq.org/tx/' + data.hash" target="_blank">view on blockchain</a></button> -->
                    </li>
                  </ul>
                   
              </div>
     </div>
    <ViewUpload v-if="showUpload" @close="viewUpload(dataInfo)" :data="dataInfo" :format="item.fileFormat"></ViewUpload>
    <FinalApprove v-if="showUploadApprover" @close="openUploadApprover('2')" :step="step"></FinalApprove>
    <UploadBusinessPlan v-if="openBusinessPlan" @close="uploadBusinessP()"></UploadBusinessPlan>
   
  
  </div>
  
</template>
        
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'
  import FinalApprove from '../components/modal/Documents/FinalApprove.vue'
  import UploadBusinessPlan from '../components/modal/Documents/UploadBusinessPlan.vue';

  export default {
      name: 'BusinessPlan',
      components: {
        TheTopbar,
        Header,
        ViewUpload,
        FinalApprove,
        UploadBusinessPlan
      },
      
       data: function() {
        return { 
        
          currentStep: 3,
          idTypes:[],
          isApproved: false,
          isRejected: false,
          showUpload: false,
          dataInfo:{},
          isLoading: false,
          isApplicationForm: false,
          isProbityForm: false,
          isCompanyProbityForm: false,
          isInvestCommitment: false,
          isAoi: false,
          isCoi: false,
          isGis: false,
          isAfs: false,
          isDirectorsCert: false,
          isITR: false,
          isRules: false,
          isPolicies: false,
          isCPP: false,
          isNameChange: false,
          showUploadApprover: false,
          status:'',
          fileType: 'BUSINESS PLAN',
          item:{
            fileFormat:''
          },
          step: '2',
          openBusinessPlan: false,

      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
       
        busPlanOnly(){
          return this.idTypes.filter(item => item.fileType === this.fileType)
        }
       
     
      },
      methods:{
        uploadBusinessP(){
          this.openBusinessPlan = !this.openBusinessPlan
        },
        openUploadApprover(step){
          this.step = step
          this.showUploadApprover = !this.showUploadApprover
        },
        getPoid(){
          this.isLoading=true
          const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/' 
              }
              this.axios(options)
              .then((response) => {
                this.isLoading = false
                this.idTypes = response.data

                let item = [];
                this.idTypes.forEach((el, i) => {
                  item.push(el);
                  this.imgUrl = el.imageFile
                 
                });
                this.item.fileFormat = this.imgUrl.split('.').pop();
               
              }).catch((err) => {
                  
              })
        },
        viewUpload(docData){
          this.dataInfo = docData
          this.showUpload = !this.showUpload
        },
       

        formatDate: function(date) {
        return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
         
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile/',
              }
              this.axios(options)
              .then((response) => {
                
                this.show.profile = response.data;
                this.show.corpDetails = response.data.corpDetails
                
              
              }).catch((err) => {
                  
              })
          },
       

        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
        this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
     
      mounted(){
          this.sessionCheck();
          this.getPoid();
          
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
    color: white;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .placeholder-circle {
    width: 25px;
    height: 25px;
    border: 1px solid #ccc; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  .approved-circle{
    width: 25px;
    height: 25px;
    background-color: #c69903; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  .btn-approve{
       width: 100%;
       background-color: green !important;
        color: white;
    }
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 200px;
    height: 50px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 10px;
    white-space: wrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
    padding: 5px 10px; /* Adjust the padding as needed to fit text */
    max-width: 100%; /* Ensure the button doesn't grow beyond its container */
    font-size: 10px; /* Adjust the font size as needed */
    color: black;
    }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}
.button{
  background-color: transparent !important;
  border: transparent !important;
}
   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
 
  
  .active {
    background-color: #b47e27 !important;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #053d5c !important;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}
.loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
